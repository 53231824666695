import { GenericIconProps } from '../types';

export const Bin: React.FC<GenericIconProps> = ({ color = 'currentColor', size, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.00033H3.33333H14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6673 3.99967C13.6673 3.44739 13.2196 2.99967 12.6673 2.99967C12.115 2.99967 11.6673 3.44739 11.6673 3.99967H13.6673ZM4.33398 3.99967C4.33398 3.44739 3.88627 2.99967 3.33398 2.99967C2.7817 2.99967 2.33398 3.44739 2.33398 3.99967H4.33398ZM4.33398 3.99967C4.33398 4.55196 4.7817 4.99967 5.33398 4.99967C5.88627 4.99967 6.33398 4.55196 6.33398 3.99967H4.33398ZM9.66732 3.99967C9.66732 4.55196 10.115 4.99967 10.6673 4.99967C11.2196 4.99967 11.6673 4.55196 11.6673 3.99967H9.66732ZM11.6673 3.99967V13.333H13.6673V3.99967H11.6673ZM11.6673 13.333C11.6673 13.5171 11.5181 13.6663 11.334 13.6663V15.6663C12.6226 15.6663 13.6673 14.6217 13.6673 13.333H11.6673ZM11.334 13.6663H4.66732V15.6663H11.334V13.6663ZM4.66732 13.6663C4.48322 13.6663 4.33398 13.5171 4.33398 13.333H2.33398C2.33398 14.6217 3.37865 15.6663 4.66732 15.6663V13.6663ZM4.33398 13.333V3.99967H2.33398V13.333H4.33398ZM6.33398 3.99967V2.66634H4.33398V3.99967H6.33398ZM6.33398 2.66634C6.33398 2.48225 6.48322 2.33301 6.66732 2.33301V0.333008C5.37865 0.333008 4.33398 1.37768 4.33398 2.66634H6.33398ZM6.66732 2.33301H9.33398V0.333008H6.66732V2.33301ZM9.33398 2.33301C9.51808 2.33301 9.66732 2.48225 9.66732 2.66634H11.6673C11.6673 1.37768 10.6226 0.333008 9.33398 0.333008V2.33301ZM9.66732 2.66634V3.99967H11.6673V2.66634H9.66732Z"
        fill={color}
      />
      <path
        d="M6.66732 7.33301V11.333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33333 7.33301V11.333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
